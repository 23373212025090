import {Component, EventEmitter, Input, OnInit, Output} from '@angular/core';
import { BehaviorSubject } from 'rxjs';
import { ISectionDto } from '../../dtos/section-dto';
import { IFilter } from '../../entities/filter';
import { IProvider } from '../../interfaces/provider.interface';
import { FilmProvider } from '../../providers/film.provider';
import { FilmService } from '../../services/film.service';
import { AwardService } from "../../services/award.service";
import { MatTabChangeEvent } from "@angular/material/tabs";

@Component({
  selector: 'fspro-project-tabs',
  templateUrl: './list-project-tabs.component.html',
  styleUrls: ['./list-project-tabs.component.scss']
})
export class ListProjectTabsComponent implements OnInit {


  private _sections: ISectionDto[] = [];
  private _currentListFilmIdentifier = '';
  public orderedAwards = [];

  @Input() public set sections(value: ISectionDto[]) {
    this._sections = value;
    if (value.length > 0) {
      this.setDefaultProvider();
    }
  };
  public get sections(): ISectionDto[] {
    return this._sections;
  }

  @Input() public parameters: IFilter[] = [];
  @Input() public isAdmin: boolean = false;
  @Input() public inkBarColorClass: string = null;
  @Input() public selectedTabIndex: number = null;
  @Input() public awards = [];
  @Output() public tabChanged = new EventEmitter<number>();

  public provider$ = new BehaviorSubject<IProvider | null>(null);

  constructor(private filmService: FilmService, private awardService: AwardService) {}

  ngOnInit(): void {}

  private getFilmProvider(listFilmIdentifier: string): IProvider {
    const filterListFilm = this.getFilterListFilm(listFilmIdentifier);
    let filters = this.parameters;
    filters.push(filterListFilm);
    return new FilmProvider(this.filmService, filters);
  }

  onTabChange(event: MatTabChangeEvent): void {
    // To avoid duplicate the call
    this.orderedAwards = [];
    const selectedTab = event.index;
    const section = this.sections[selectedTab];
    this.loadNewProvider(section);
    this.tabChanged.emit(event.index);
  }

  getOrderedAwards(sectionIdentifier: string) {
    this.awardService.getOrderedAwardsForSection(sectionIdentifier).toPromise().then((awards) => {
      this.orderedAwards = awards;
    })
  }

  getFilterListFilm(listFilmIdentifier: string): IFilter {
    return {
      field: 'listsFilms',
      value: listFilmIdentifier,
      type: 0
    }
  }

  loadNewProvider(section: ISectionDto): void {
    if (this._currentListFilmIdentifier != section.listFilmIdentifier) {
      this._currentListFilmIdentifier = section.listFilmIdentifier
      this.provider$.next(this.getFilmProvider(section.listFilmIdentifier));
      this.getOrderedAwards(section.listFilmIdentifier);
    }
  }


  private setDefaultProvider(): void {
    const section: ISectionDto = (this.sections && this.selectedTabIndex && this.selectedTabIndex >= 0) ?
      this.sections[this.selectedTabIndex]
      : this.sections[0];

    this.loadNewProvider(section);
  }
}
