import { Component, EventEmitter, Input, OnInit, Output } from '@angular/core';
import { FormControl, FormGroup } from '@angular/forms';
import { Subscription } from 'rxjs';

export type FilterGroupField = MultipleSelectProps | SingleSelectProps;

interface MultipleSelectProps extends IFilterGroupFieldBase {
  multiple: true,
  default?: IFilterData[]
}

interface SingleSelectProps extends IFilterGroupFieldBase {
  multiple: false,
  default?: IFilterData
}

interface IFilterGroupFieldBase {
  fieldName: string,
  placeholder: string,
  data: IFilterData[],
}

export interface IFilterData {
  id: number | string,
  name: string
}

@Component({
  selector: 'fspro-filter-group',
  templateUrl: './filter-group.component.html',
  styleUrls: ['./filter-group.component.scss']
})
export class FilterGroupComponent implements OnInit {

  private _valueChangeSubject: Subscription;

  @Input() set listFields(value: FilterGroupField[]) {
    this._listFields = value;
  }

  get listFields(): FilterGroupField[] {
    return this._listFields;
  }


  @Output() onChange = new EventEmitter();

  isThereAnyMultiValuesInFilter = false;

  private _listFields: FilterGroupField[];

  searchFilmForm = new FormGroup({});

  searchCriteria: any;

  constructor() {

  }

  private buildFilterFields(): void {
    const initializeFields = this._listFields.reduce(
      (acc, val) => ({ ...acc, [val.fieldName]: new FormControl(this.getDefaultValue(val)) }),
      {}
    );
    this.searchFilmForm = new FormGroup(initializeFields);
  }

  private getDefaultValue(field: FilterGroupField): IFilterData | IFilterData[] {
    if (field.multiple) {
      return field.default || [];
    } else {
      const id = (Array.isArray(field.default) ? field.default[0] : field.default) || null;
      if (id && typeof id === 'string') {
        return field.data.find(x => x.id === id) || null;
      }
      return null;
    }
  }

  ngOnInit(): void {
    this.loadDataAsOriginal();
  }

  private startListeningValueChange() {

    if (this._valueChangeSubject) {
      this._valueChangeSubject.unsubscribe();
    }

    this._valueChangeSubject = this.searchFilmForm?.valueChanges.subscribe(values => {
      this.computeAndEmitData(values);
    });
  }

  private computeAndEmitData(values): void {
    const cloneValues = { ...values };
    // delete cloneValues.availableToWatch;

    for (let key in cloneValues) {
      let newCriteria = {};
      if (Array.isArray(values[key])) {
        newCriteria = { [key]: values[key].map(x => x.id ? x.id : parseInt(x) || x) }
      } else {
        newCriteria = { [key]: values[key] ? [values[key].id ? values[key].id : parseInt(values[key]) || values[key]] : [] }
      }

      this.searchCriteria = { ...this.searchCriteria, ...newCriteria }
    }

    this.refreshFilterConditions();

    this.onChange.emit(this.searchCriteria);
  }

  private refreshFilterConditions(): void {
    const conditions = this.searchFilmForm.value;

    for (let key in conditions) {
      if (Array.isArray(conditions[key]) && conditions[key].length > 0) {
        this.isThereAnyMultiValuesInFilter = true;
        break;
      } else {
        this.isThereAnyMultiValuesInFilter = false;
      }
    }
  }

  loadDataAsOriginal(): void {
    if (this._listFields) {
      this.buildFilterFields();
      this.computeAndEmitData(this.searchFilmForm.value);
      this.startListeningValueChange();
    }
  }

  compareSelectValues(selectedValue: IFilterData, compareValue: any): boolean {
    const valueToCompare = compareValue?.id ? compareValue.id : parseInt(compareValue, 10);
    return selectedValue?.id === valueToCompare;
  }

  getFormField(fieldName: string) {
    return this.searchFilmForm.get(fieldName);
  }

  getLabels(fieldName: string) {
    return this.searchFilmForm.get(fieldName).value.map(
      filter => filter.id
        ? filter
        : this.listFields.filter(
          listField => listField.fieldName === fieldName
        )[0].data.filter(
          dataFilter => dataFilter.id === parseInt(filter, 10)
        )[0]
    );
  }

  getListFieldType(type: 'single' | 'multiple') {
    return this.listFields?.filter(x => x.multiple == (type === 'multiple'))
  }


  onRemoveItemInFilter(value, fieldName) {
    const currentField = this.searchFilmForm.get(fieldName);

    if (Array.isArray(this.searchFilmForm.get(fieldName).value)) {
      const fieldValue = currentField.value;

      const values = {...this.searchFilmForm.value};
      values[fieldName] = values[fieldName].filter(
        x => x.id ? x.id !== value.id : parseInt(x, 10) !== value.id
      );

      this.computeAndEmitData({ ...values });

      this.searchFilmForm.get(fieldName).setValue(fieldValue.filter(
        x => x.id ? x.id !== value.id : parseInt(x, 10) !== value.id
      ));
    } else {
      this.searchFilmForm.get(fieldName).setValue(null);
    }
  }

  resetFilters() {
    for (let controlsKey in this.searchFilmForm.controls) {
      this.searchFilmForm.get(controlsKey).setValue([]);
    }
    this.onChange.emit({});
  }
}
