import { Injectable } from '@angular/core';
import { Title, Meta } from '@angular/platform-browser';
import { TranslateService } from "@ngx-translate/core";

export enum Page {
  HOME = "HOME",
  DIRECTORS = "DIRECTORS",
  FESTIVALS = "FESTIVALS",
  LABELS = "LABELS",
  LINE_UPS = "LINE_UPS",
  COMMISSIONS = "COMMISSION",
  SCOPE_EXPANDED = "SCOPE_EXPANDED",
  FILMS = "FILMS",
  SIGN_UP = "SIGN_UP",
  PROFILE = "PROFILE",
  VERIFY = "VERIFICATION",
  FILM_PAGE = "FILM_PAGE",
  DIRECTOR_PAGE = "DIRECTOR_PAGE",
  TALENT_PAGE = "TALENT_PAGE",
  FESTIVAL_PAGE = "FESTIVAL_PAGE",
  LABEL_PAGE = "LABEL_PAGE",
  LINE_UP_PAGE = "LINE_UP_PAGE",
  COMMISSION_PAGE = "COMMISSION_PAGE",
  MY_BOOKINGS = "MY_BOOKINGS",
  DYNAMIC_PAGE = "DYNAMIC_PAGE"
}

export class TranslationParameters {
  filmName?: string|null = null;
  year?: number|null = null;
  directorName?: string|null = null;
  talentName?: string|null = null;
  festivalName?: string|null = null;
  edition?: string|null = null;
  labelName?: string|null = null;
  dynamicPageTitle?: string|null = null;
}

@Injectable()
export class MetaService {

  constructor(private meta: Meta, private title: Title, private translateService: TranslateService) {}

  updateTitleAndMeta(page: string, params: TranslationParameters|null = null): void {
    const titlePrefix = 'Festival Scope Pro - ';
    switch (page) {
      case Page.HOME:
        this.translateService.get('meta_description.home').subscribe({
            next: (text) => this.meta.updateTag({name: 'description', content: text})
          }
        );
        this.title.setTitle(titlePrefix + 'Discover');
        break;
      case Page.DIRECTORS:
        this.translateService.get('meta_description.director.list').subscribe({
            next: (text) => this.meta.updateTag({name: 'description', content: text})
          }
        );
        this.title.setTitle(titlePrefix + 'Directors');
        break;
      case Page.FESTIVALS:
        this.translateService.get('meta_description.festival.list').subscribe({
            next: (text) => this.meta.updateTag({name: 'description', content: text})
          }
        );
        this.title.setTitle(titlePrefix + 'Festivals');
        break;
      case Page.LABELS:
        this.translateService.get('meta_description.label.list').subscribe({
            next: (text) => this.meta.updateTag({name: 'description', content: text})
          }
        );
        this.title.setTitle(titlePrefix + 'Labels');
        break;
      case Page.COMMISSIONS:
        this.translateService.get('meta_description.label.list').subscribe({
            next: (text) => this.meta.updateTag({name: 'description', content: text})
          }
        );
        this.title.setTitle(titlePrefix + 'Commissions');
        break;
      case Page.LINE_UPS:
        this.title.setTitle(titlePrefix + 'Line ups');
        break;
      case Page.SCOPE_EXPANDED:
        this.translateService.get('meta_description.scopeExpanded').subscribe({
            next: (text) => this.meta.updateTag({name: 'description', content: text})
          }
        );
        this.title.setTitle(titlePrefix + 'Scope Expanded');
        break;
      case Page.FILMS:
        this.translateService.get('meta_description.film.list').subscribe({
            next: (text) => this.meta.updateTag({name: 'description', content: text})
          }
        );
        this.title.setTitle(titlePrefix + 'Films');
        break;
      case Page.SIGN_UP:
        this.translateService.get('meta_description.sign_up').subscribe({
            next: (text) => this.meta.updateTag({name: 'description', content: text})
          }
        );
        this.title.setTitle(titlePrefix + 'Join');
        break;
      case Page.PROFILE:
        this.title.setTitle(titlePrefix + 'Profile');
        break;
      case Page.VERIFY:
        this.title.setTitle(titlePrefix + 'Verification');
        break;
      case Page.FILM_PAGE:
        this.translateService.get('meta_description.film.entity', params).subscribe({
            next: (text) => this.meta.updateTag({name: 'description', content: text})
          }
        );
        this.title.setTitle(titlePrefix + 'Film ' + params.filmName);
        break;
      case Page.DIRECTOR_PAGE:
        this.translateService.get('meta_description.director.entity', params).subscribe({
            next: (text) => this.meta.updateTag({name: 'description', content: text})
          }
        );
        this.title.setTitle(titlePrefix + 'Director ' + params.directorName);
        break;
      case Page.TALENT_PAGE:
        this.translateService.get('meta_description.talent.entity', params).subscribe({
            next: (text) => this.meta.updateTag({name: 'description', content: text})
          }
        );
        this.title.setTitle(titlePrefix + 'Talent ' + params.talentName);
        break;
      case Page.FESTIVAL_PAGE:
        this.translateService.get('meta_description.festival.entity', params).subscribe({
            next: (text) => this.meta.updateTag({name: 'description', content: text})
          }
        );
        this.title.setTitle(titlePrefix + 'Festival ' + params.festivalName);
        break;
      case Page.LABEL_PAGE:
        this.translateService.get('meta_description.label.entity', params).subscribe({
            next: (text) => this.meta.updateTag({name: 'description', content: text})
          }
        );
        this.title.setTitle(titlePrefix + 'Label ' + params.labelName);
        break;
      case Page.COMMISSION_PAGE:
        this.translateService.get('meta_description.commission.entity', params).subscribe({
            next: (text) => this.meta.updateTag({name: 'description', content: text})
          }
        );
        this.title.setTitle(titlePrefix + 'Film Commission ' + params.labelName);
        break;
      case Page.LINE_UP_PAGE:
        this.title.setTitle(titlePrefix + 'Line up ' + params.labelName);
        break;
      case Page.MY_BOOKINGS:
        this.title.setTitle(titlePrefix + 'My Bookings');
        break;
      case Page.DYNAMIC_PAGE:
        this.title.setTitle(titlePrefix + params.dynamicPageTitle);
        break;
    }
  }


}
