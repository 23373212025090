<div class="listfilter">
  <div class="grid film-main-wrapper">
    <form [formGroup]="searchFilmForm">
      <div class="fspro-mat-grid-list-wrapper">
        <mat-grid-list cols="12" rowHeight="45" gutterSize="10">
          <mat-grid-tile [colspan]="6" [responsiveColSpan]="{ xs: 6, sm: 4, md: 4, lg: 5, xl: 5 }" *ngIf="listFields.length <= 1"></mat-grid-tile>
          <mat-grid-tile *ngFor="let field of listFields; let i = index" [colspan]="6" [responsiveColSpan]="{ xs: 6, sm: 4, md: 4, lg: 5, xl: 5 }">
            <div class="fspro-control-dropdown-wrapper">
              <mat-form-field appearance="outline">
                <mat-select [placeholder]="field.placeholder | translate" [formControlName]="field.fieldName"
                [multiple]="field.multiple"
                [compareWith]="compareSelectValues">
                  <mat-select-trigger *ngIf="field.multiple">
                    {{getFormField(field.fieldName).value ?((getFormField(field.fieldName).value[0]?.name) | translate ): ''}}

                    <span *ngIf="getFormField(field.fieldName).value?.length > 1">
                      (+{{getFormField(field.fieldName).value.length - 1}} {{getFormField(field.fieldName).value?.length === 2 ? 'other' : 'others'}})
                    </span>
                  </mat-select-trigger>

                  <mat-option *ngFor="let opt of field.data; let j = index" [value]="opt">
                    <span class="option-text">{{opt?.name | translate}}</span>
                  </mat-option>
                </mat-select>
              </mat-form-field>
            </div>
          </mat-grid-tile>
          <mat-grid-tile [colspan]="2" [responsiveColSpan]="{ xs: 3, sm: 4, md: 4, lg: 2, xl: 2 }">
            <button type="button" (click)="onReload()" class="listfilter__button listfilter__item listfilter__button--reload">{{ 'fspro.front.mynotes.listfilter.button.reload'| translate }}</button>
          </mat-grid-tile>
        </mat-grid-list>
      </div>
    </form>
  </div>
</div>
