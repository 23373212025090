import {
  Component,
  Input,
  OnInit,
  Output,
  EventEmitter,
  ViewChild,
  ElementRef,
  AfterViewInit,
  ChangeDetectorRef,
  OnDestroy
} from '@angular/core';
import { MatSnackBar } from '@angular/material/snack-bar';
import { MatDialog } from '@angular/material/dialog';
import { FilmHelperService } from '../../services/film.helper.service';
import { ShareInvitationDialog } from '../share-invitation-dialog/share-invitation-dialog.component';
import { DatePipe } from '@angular/common';
import { TranslateService } from "@ngx-translate/core";
import { IFilmDto } from '../../dtos/film-dto';
import { FilmService } from '../../services/film.service';
import { RequestUpdateDialog } from '../request-update-dialog/request-update-dialog.component';
import {AuthenticationService} from "../../../authentications/authentication.service";
import { BookingConditionsDialogComponent } from '../booking-conditions-dialog/booking-conditions-dialog.component';
import { ScreeningLeftDialog } from '../screening-left-dialog/screening-left-dialog.component';
import { ICanDisplayFreshStartResponseDto, ScreeningService } from '../../services/screening.service';
import { takeUntil } from 'rxjs/operators';
import { Router } from '@angular/router';
import { Subject } from 'rxjs';

@Component({
  selector: 'fspro-film-card',
  templateUrl: './film-card.component.html',
  styleUrls: ['./film-card.component.scss'],
  providers: [DatePipe]
})
export class FilmCardComponent implements OnInit, AfterViewInit, OnDestroy {

  @Input() public film: IFilmDto = null;
  @Input() public titleInPoster = false;
  @Input() public displayRemoveButton = false;
  @Input() public isAdmin = false;
  @Input() public borderBottom = false;
  @Input() public shortenDetails = false;
  @Input() public isAllowToShare = false;
  @Input() public isAllowedToAddToWatchlist;
  @Input() public theme: 'DarkMode' | 'LightMode';
  @Input() public isHiddenDirectorName = false;
  @Input() public isMyFilmsEnabled = false;

  @Output() filmRecommendationRemoved = new EventEmitter<IFilmDto>();
  @Output() removeFromWatchListEmitter = new EventEmitter<IFilmDto>();
  @Input('isCoproduction') isCoproduction = false;
  @ViewChild('titleElt', { read: ElementRef, static: false }) titleElt: ElementRef;

  public title = '';
  public restrictionMessage: string = null;
  public isLoggedIn = true;

  public maxTitleSize = 60;
  public titleSliceStep = 2;
  public isTitleOnTwoLines = false;
  public titleHeightInPixel = 31;
  public isPermissionsOpened = false;

  /**
   * In order to prevent multi click on play button.
   * During checking the condition, we must block the action
   */
  private isPlayProcessing = false;
  private _destroyed = new Subject<void>();

  constructor(
    private filmService: FilmService,
    private snackbar: MatSnackBar,
    private cd: ChangeDetectorRef,
    private dialog: MatDialog,
    private filmHelper: FilmHelperService,
    private translateService: TranslateService,
    private authenticationService: AuthenticationService,
    private screeningService: ScreeningService,
    private readonly router: Router
  ) { }

  ngOnInit(): void {
    this.computeRestrictionMessage();
    this.title = this.film.internationalTitle;
    this.isAllowedToAddToWatchlist = this.authenticationService.isUserType;
  }

  ngAfterViewInit(): void {
    if (this.titleElt && this.shortenDetails) {
      this.adjustTitleLength();
    }
  }

  isTextOverflow(elem: any): boolean {

    if (elem) {
      return (elem.offsetHeight < elem.scrollHeight);
    }
    else {
      return false;
    }
  }

  openRequestUpdate() {
    this.dialog.open(RequestUpdateDialog, {
      panelClass: 'send-message-dialog-wrapper',
      width: '480px',
      autoFocus: false,
      disableClose: true,
      data: { film: this.film }
    })
  }


  openBookingConditions() {
    this.dialog.open(BookingConditionsDialogComponent, {
      panelClass: 'send-message-dialog-wrapper',
      width: '580px',
      autoFocus: false,
      disableClose: true,
      data: { filmId: this.film.id }
    })
  }

  adjustTitleLength() {

    // Security to avoid an infinite loop
    if (this.title.length <= '...'.length + this.titleSliceStep) {
      return;
    }

    if (this.isTextOverflow(this.titleElt.nativeElement)) {

      // first we try to set th title on two lines
      if (!this.isTitleOnTwoLines) {
        this.titleHeightInPixel = this.titleHeightInPixel * 2;
        this.isTitleOnTwoLines = true;
        this.cd.detectChanges();
      }
      else {
        this.title = this.film.internationalTitle.slice(0, this.maxTitleSize).trim() + '...';
        this.maxTitleSize = this.maxTitleSize - this.titleSliceStep;
      }

      // Check later if it is enough
      setTimeout(() => this.adjustTitleLength(), 5);
    }
  }

  private computeRestrictionMessage(): void {
    this.restrictionMessage = this.filmHelper.computeRestrictionMessage(
      this.film?.availabilityWrapper,
      this.film?.isComingSoon
    );
  }

  openShareDialog(film: IFilmDto) {
    const dialogRef = this.dialog.open(ShareInvitationDialog, {
      panelClass: 'share-invitation-dialog-wrapper',
      width: '480px',
      autoFocus: false,
      disableClose: true,
      data: { film }
    })

    dialogRef.afterClosed().pipe(takeUntil(this._destroyed)).subscribe(result => {
      if (result?.isGetLinkSuccess) {
        this.film.InvitationInfoDto.sharedCounter += 1;
      }
    })
  }

  removeRecommendation(): void {
    this.filmService.removeRecommendedFilms(this.film.id).pipe(takeUntil(this._destroyed)).subscribe(
      (film) => {
        this.filmRecommendationRemoved.emit(film);
        this.snackbar.open(
          'The film ' + film.internationalTitle + ' has been removed from the recommendations',
          'Close',
          { duration: 5000, verticalPosition: 'top', horizontalPosition: 'center', panelClass: 'info-snackbar' }
        );
      }
    );
  }

  addToWatchList(): void {
    this.filmService.addToWatchList(this.film.id).pipe(takeUntil(this._destroyed)).subscribe(
      (film) => {
        this.translateService.get('fspro.front.film-card.alert.add-to-watchlist', { film_title: film.internationalTitle }).subscribe((text) => {
          this.snackbar.open(
            text,
            'Close',
            { duration: 5000, verticalPosition: 'top', horizontalPosition: 'center', panelClass: 'info-snackbar' }
          );
        });

        this.film.inWatchList = true;
      }
    );
  }

  removeFromWatchList(): void {
    this.filmService.removeFromWatchList(this.film.id).pipe(takeUntil(this._destroyed)).subscribe(
      (film) => {
        this.translateService.get('fspro.front.film-card.alert.remove-from-watchlist', { film_title: film.internationalTitle }).pipe(takeUntil(this._destroyed)).subscribe((text) => {
          this.snackbar.open(
            text,
            'Close',
            { duration: 5000, verticalPosition: 'top', horizontalPosition: 'center', panelClass: 'info-snackbar' }
          );
        });

        this.film.inWatchList = false;
        this.removeFromWatchListEmitter.emit(film);
      }
    );
  }

  hasActionButton() {
    return this.film.availabilityWrapper?.isLoggedIn ||
      this.film.trailerUrl !== null ||
      (!this.film.inWatchList && this.film.availabilityWrapper?.isLoggedIn) ||
      (this.film.inWatchList && this.film.availabilityWrapper?.isLoggedIn);
  }

  onPlayFilm() {
    if (this.isPlayProcessing) return;
    this.isPlayProcessing = true;

    if (this.film.availabilityWrapper?.allowedToFreshStart) {
      this.screeningService.canDisplayFreshStart(this.film.slug).pipe(takeUntil(this._destroyed)).subscribe((data: ICanDisplayFreshStartResponseDto) => {
        if (data.display) {
          this.openScreenLeftDialog();
        } else {
          this.router.navigateByUrl(this.film.filmAutoPlayUrl);
        }
      });

    } else {
      this.router.navigateByUrl(this.film.filmAutoPlayUrl);
    }
  }

  private openScreenLeftDialog() {
    const dialogRef = this.dialog.open(ScreeningLeftDialog, {
      data: { film: this.film },
      panelClass: ['send-message-dialog-wrapper', 'large'],
      width: '480px',
      autoFocus: false,
      disableClose: true,
      position: {
        top: "50px"
      }
    });

    dialogRef.afterClosed().pipe(takeUntil(this._destroyed)).subscribe(() => {
      this.isPlayProcessing = false;
    });
  }

  ngOnDestroy(): void {
    this._destroyed.next();
    this._destroyed.complete();
  }
}
