import { Injectable } from '@angular/core';
import { TranslateService } from '@ngx-translate/core';
import { BehaviorSubject, Observable, of, Subscription, zip } from 'rxjs';
import { map } from 'rxjs/operators';
import { CollectionService } from '../shared/services/collection.service';
import { SearchResultSection, SectionType } from '../interfaces/search-result.interface';
import { DirectorService } from '../shared/services/director.service';
import { FestivalYearService } from '../shared/services/festival-year.service';
import { FilmService } from '../shared/services/film.service';

@Injectable()
export class SearchResultProvider {
  constructor(
    private festivalYearService: FestivalYearService,
    private collectionService: CollectionService,
    private directorService: DirectorService,
    private filmService: FilmService,
    private translateService: TranslateService
  ) { }

  currentSubscription: Subscription;
  searchResult = new BehaviorSubject<SearchResultSection[]>(null);

  festivalSearch = (searchTerm: string, searchOffset: number = 0) => {
    return this.festivalYearService
      .getListForSearchPage(searchOffset, 6, null, searchTerm)
      .pipe(
        map((data) => {
          let items = data.map((item) => {
            return {
              mainCaption: `${item?.festival?.name} ${item?.year}`,
              subCaption: item?.country,
              imgUrl: item?.logoUrl,
              pageUrl: item.festival.coproduction
                ? `/coproduction/show/${item?.festival?.slug}/${item?.year}`
                : `/festival/show/${item?.festival?.slug}/${item?.year}`,
            };
          });
          return {
            type: 'Festival' as const,
            searchResultItems: items
          }
        })
      );
  };

  labelSearch = (searchTerm: string, searchOffset: number = 0) => {
    return this.collectionService.getList(searchOffset, 6, 'label', searchTerm).pipe(
      map((data) => {
        let items = data.map((item) => {
          return {
            mainCaption: item?.name,
            imgUrl: item?.logoUrl,
            pageUrl: `/label/show/${item?.slug}`,
          };
        });
        return {
          type: 'Label' as const,
          searchResultItems: items
        }
      })
    );
  };

  lineupSearch = (searchTerm: string, searchOffset: number = 0) => {
    return this.collectionService.getList(searchOffset, 6, 'lineup', searchTerm).pipe(
      map((data) => {
        let items = data.map((item) => {
          return {
            mainCaption: item?.name,
            imgUrl: item?.logoUrl,
            pageUrl: `/line-up/show/${item?.slug}`,
          };
        });
        return {
          type: 'Lineup' as const,
          searchResultItems: items
        }
      })
    );
  };

  filmSearch = (searchTerm: string, searchOffset: number = 0) => {
    return this.filmService.searchFilms(searchOffset, 6, null, searchTerm).pipe(
      map((data) => {

        let items = data.map((item) => {
          return {
            mainCaption: item?.internationalTitle,
            subCaption: item?.originalTitle ? `(${item.originalTitle})` : '',
            imgUrl: item?.posterUrl,
            additionalInfo1: item?.director ? `${this.translateService.instant('fspro.front.search.label.films.directed_by')} ${item.director}` : null,
            additionalInfo2: [item?.countries, item?.year, item?.duration ? `${item?.duration}  ${this.translateService.instant('fspro.front.search.label.min')}`: ''].filter(Boolean),
            pageUrl: item?.filmPageUrl,
            availabilityWrapper: item?.availabilityWrapper,
          };
        });
        return {
          type: 'Film' as const,
          searchResultItems: items
        }
      })
    );
  };

  directorSearch = (searchTerm: string, searchOffset: number = 0) => {
    return this.directorService.getDirectors(searchOffset, 6, null, searchTerm).pipe(
      map((data) => {
        let items = data.map((item) => {
          return {
            mainCaption: item?.lastName
              ? item?.firstName + ' ' + item?.lastName
              : item?.firstName,
            subCaption: item?.countries?.join(', '),
            imgUrl: item?.pictureList,
            pageUrl: `/director/${item?.slug}`,
          };
        });
        return {
          type: 'Director' as const,
          searchResultItems: items
        }
      })
    );
  };

  searchMap = {
    Festival: this.festivalSearch,
    Label: this.labelSearch,
    Lineup: this.lineupSearch,
    Film: this.filmSearch,
    Director: this.directorSearch
  };

  search = (searchTerm: string, searchType: SectionType,  searchOffset = 0) => {
    this.currentSubscription?.unsubscribe();
    if (searchType == 'All') {
      this.currentSubscription = zip(
        this.festivalSearch(searchTerm),
        this.labelSearch(searchTerm),
        this.lineupSearch(searchTerm),
        this.filmSearch(searchTerm, searchOffset),
        this.directorSearch(searchTerm)
      ).subscribe({
        next: (data) => {
          this.searchResult.next(data);
        }
      });
    }
    else {
      let searchObserrvable : Observable<SearchResultSection> = this.searchMap[searchType](searchTerm, searchOffset);
      this.currentSubscription = searchObserrvable.subscribe({
        next: (data) => {
          this.searchResult.next([data]);
        }
      });
    }
  };
}
