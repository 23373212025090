import { Component, EventEmitter, OnDestroy, OnInit, Output } from "@angular/core";
import { ActivatedRoute, Router } from "@angular/router";
import { BehaviorSubject, Subject } from "rxjs";
import { debounceTime, distinctUntilChanged, takeUntil, tap } from "rxjs/operators";
import { SearchResultItem, SearchResultSection, SectionType } from "src/app/interfaces/search-result.interface";
import { SearchResultProvider } from "src/app/services/search-result-provider.service";
import { FormControl, FormGroup } from '@angular/forms';

@Component({
  selector: 'search-popup',
  templateUrl: './search-popup.component.html',
  styleUrls: ['./search-popup.component.scss'],
  providers: [SearchResultProvider]
})
export class SearchPopupComponent implements OnInit, OnDestroy {

  searchForm = new FormGroup({
    searchText: new FormControl<string>(''),
    focusedSection: new FormControl<SectionType>('All')
  })

  @Output() onSearchChanged = new EventEmitter<void>();

  private readonly _destroy$ = new Subject();
  filmResults = new BehaviorSubject<SearchResultItem[]>([]);
  lineUpResults = new BehaviorSubject<SearchResultItem[]>([]);
  festivalResults = new BehaviorSubject<SearchResultItem[]>([]);
  labelResults = new BehaviorSubject<SearchResultItem[]>([]);
  directorResults = new BehaviorSubject<SearchResultItem[]>([]);
  searchTextSubject = new BehaviorSubject<string>(null);

  public focusedSections: { type: SectionType, name: string }[] = [
    {
      type: 'All',
      name: 'All'
    },
    {
      type: 'Film',
      name: 'fspro.front.search.title.films'
    },
    {
      type: 'Director',
      name: 'fspro.front.search.title.directors'
    },
    {
      type: 'Festival',
      name: 'fspro.front.search.title.festivals'
    },
    {
      type: 'Label',
      name: 'fspro.front.search.title.labels'
    },
    {
      type: 'Lineup',
      name: 'fspro.front.search.title.line_ups'
    }
  ];
  constructor(
    private readonly searchResultProvider: SearchResultProvider,
    private readonly activatedRoute: ActivatedRoute,
    private readonly router: Router
  ) {
    this.activatedRoute.queryParams.pipe(
      takeUntil(this._destroy$),
      tap(({ searchValue, type }) => {
        this.searchForm.patchValue({ searchText: searchValue, focusedSection: this.toSectionType(type) });
        if(searchValue && searchValue.length >= 3) {
          this.doSearch();
        }
      })
    ).subscribe();

    this.searchForm.get('searchText').valueChanges.pipe(
      takeUntil(this._destroy$),
      tap((value: string) => this.searchTextSubject.next(value))
    ).subscribe();
  }

  private toSectionType(sequence: string): SectionType {
    if (!sequence) {
      return 'All';
    }
    return `${sequence.charAt(0).toUpperCase() }${sequence.slice(1)}` as SectionType;
  }

  setCommittedSearchStatus() {
    this.festivalResults.next([]);
    this.labelResults.next([]);
    this.lineUpResults.next([]);
    this.filmResults.next([]);
    this.directorResults.next([]);
  }

  ngOnInit(): void {
    this.searchResultProvider.searchResult.subscribe(data => {
      this.handleSearchResult(data);
    });
    this.searchTextSubject.pipe(
      takeUntil(this._destroy$),
      debounceTime(400),
      distinctUntilChanged()
    ).subscribe(() => this.doSearch());
  }

  ngOnDestroy(): void {
    this._destroy$.next();
    this._destroy$.complete();
  }

  doSearch(): void {
    if(this.searchForm.get('searchText').value && this.searchForm.get('searchText').value.length >= 3)
    {
      this.setCommittedSearchStatus();
      this.searchResultProvider.search(this.searchForm.get('searchText').value, this.searchForm.get('focusedSection').value, 0);
    }
  }

  goToSearchPage() {
    // window.location.href = `/search?searchValue=${this.searchText}&type=${this.focusedSection?.toString()?.toLocaleLowerCase()}`;
    this.router.navigate(['search'], {
      queryParams: {
        searchValue: this.searchForm.get('searchText').value || '',
        type: this.searchForm.get('focusedSection').value || 'All'
      }
    });
    this.onSearchChanged.emit();
  }

  private handleSearchResult(allResults: SearchResultSection[]) {
    if (allResults) {
      this.festivalResults.next(allResults.find(x => x.type === 'Festival')?.searchResultItems);
      this.labelResults.next(allResults.find(x => x.type === 'Label')?.searchResultItems);
      this.lineUpResults.next(allResults.find(x => x.type === 'Lineup')?.searchResultItems);
      this.filmResults.next(allResults.find(x => x.type === 'Film')?.searchResultItems);
      this.directorResults.next(allResults.find(x => x.type === 'Director')?.searchResultItems);
    }
  }
}
